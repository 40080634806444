import React, { useState, useEffect } from 'react';
import { Form, Row, Select, Input} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { validateVkn } from '../../../../utils/validators';
import { fetchTaxOffices, fetchCompanies } from '../../../../apiServices/commonApi';
import { setDistricts, setTaxOffices,setCompanies, setShowTaxOffice,setUserInfo } from '../../../../store/reducers/commonSlice';
import { setSupplierTitle } from '../../../../store/reducers/userSlice';


const { Option } = Select;

function UserStepForm({ setProvinceName, form, setSupTitle,setCompanyType,setDistrict, setActiveNext }) { 
    const dispatch = useDispatch();
    const [loading, setloading] = useState(false);    
    const { districts, provinces, taxOffices, companies, showTaxOffice } = useSelector(({ common }) => common);
    const [taxOffice, setTaxOffice] = useState([]);
  
    const onChangeProvince = async (value) => {
        dispatch( setShowTaxOffice(true));        
        form.resetFields(['district']);
        form.resetFields(['taxAdministration']);
        const _province = provinces.length > 0 && provinces.find((province) => province.name === value);
        if (_province) {
            setloading(true);
            setProvinceName(_province.name);
            dispatch(setDistricts(_province.districts));
            const response = await fetchTaxOffices(_province.provinceId);
            setTaxOffice(response)
           
            if (response) {
                dispatch(setTaxOffices(response));
                setloading(false);
            }
        }
    };

    const userForm = () => {

        const values = form.getFieldsValue();
        setCompanyType(values.companyType);
        setDistrict(values.district);
        setSupTitle(values.title);
        dispatch(setSupplierTitle(values.title));
        setActiveNext(true);
        if (values.taxId && values.province && values.taxAdministration) {
            const _province =
                provinces.length > 0 && provinces.find((province) => province.name === values.province);
        
            const taxAdminCode = taxOffice.filter(item =>  item.name === values.taxAdministration)                
            const payload = {
                taxId: values.taxId,
                provinceId: _province.provinceId,
                taxAdmin: taxAdminCode[0]?.code,
            };
            dispatch(setUserInfo(payload));
       
    }
}

    const getCompanies = async () => {
        const response = await fetchCompanies();
        if (response) {
            dispatch(setCompanies(response));
        }  
    };

    useEffect(() => { 
        getCompanies();        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    return (
        <>
            <Row gutter={0}>
                <Form.Item name="companyType" rules={[{ required: true }]}>
                    <Select placeholder="Şirket Türü"  style={{ width: '350px', marginRight: '20px' }} >
                        {companies.map((company) => (
                            <Option key={`bank-option-${company.name}`} value={company.name}>
                                {company.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    style={{ width: '350px' }}
                    name="taxId"
                    rules={[
                        {
                            required: true,
                            validator: (_, value) => validateVkn(value),
                        },
                    ]}>
                    <Input className="w-100" placeholder="VKN/TCKN" />
                </Form.Item>
            </Row>
            <Row gutter={0}>
                <Form.Item
                    name="province"
                    rules={[
                        {
                            required: true,
                            message: 'Lütfen bir il seçiniz!',
                        },
                    ]}>
                    <Select
                        style={{ width: '350px', marginRight: '20px' }}
                        placeholder="İl"
                        onChange={onChangeProvince}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            String(option.props.children)
                              .toLocaleLowerCase('tr')
                              .includes(input.toLocaleLowerCase('tr'))
                          }
                        showSearch>
                        {provinces.map((provnce) => (
                            <Option key={`province-${provnce.name}`} value={provnce.name}>
                                {provnce.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                {showTaxOffice && (
                    <Form.Item
                        name="district"
                        rules={[
                            {
                                required: true,
                                message: 'Lütfen bir ilçe seçiniz!',
                            },
                        ]}>
                        <Select
                            style={{ width: '350px' }}
                            placeholder="İlçe"
                            loading={loading}
                            optionFilterProp="children"
                            showSearch>
                            {districts.length > 0 &&
                                districts.map((district, index) => (
                                    <Option key={`district-${index}`} value={district}>
                                        {district}
                                    </Option>
                                ))}
                        </Select>
                    </Form.Item>
                )}
            </Row>
            <Row gutter={0}>
                {showTaxOffice && (
                    <Form.Item
                        name="taxAdministration"
                        rules={[
                            {
                                required: true,
                                message: 'Lütfen bir il seçiniz!',
                            },
                        ]}>
                        <Select
                            style={{ width: '350px' }}
                            placeholder="Vergi Dairesi"
                            loading={loading}
                            optionFilterProp="children"
                            // onChange={() => setBtnShow(true)}
                            showSearch>
                            {taxOffices.length > 0 &&
                                taxOffices.map((taxOfc, id) => (
                                    <Option key={`tax-Administration-${id}`} value={taxOfc.name}>
                                        {taxOfc.name}
                                    </Option>
                                ))}
                        </Select>
                    </Form.Item>   
                )}                
            </Row>

            {/* <Row gutter={0} style={{alignItems:'center'}}>
                {showTaxOffice && (
                    <>
                     <div style={{width:'200px', height:'100px'}}>
                        <img src={image} alt=''/>
                    </div>
                   <Button  type="primary" onClick={() => getCaptcha()} style={{marginLeft:'25px'}}><ReloadOutlined /></Button>
                    </>
                   
                )}     
            </Row> */}
            {/* <Row gutter={0}>
                {showTaxOffice && (
                    <Form.Item
                        style={{ width: '350px' }}
                        name="securityCode"
                        onChange={(e) => onChangeCode(e)}
                        rules={[
                            {
                                required: true,
                                message: 'Lütfen yukarıdaki karakterleri giriniz.',
                            },
                        ]}>
                        <Input  placeholder="Kod"  maxLength={6} />
                    </Form.Item>
                )}     
            </Row> */}

            {/* <Row gutter={0}>
                {showTaxOffice &&  (
                    btnShow && (
                        <Form.Item>
                            <Button type="primary" size="large" loading={titleBtn}  onClick={onChangeTitle} >
                                Ünvanı Getir
                            </Button>
                        </Form.Item>
                    )
                )}                
            </Row>
          */}
         
            <Row gutter={0}>
                {showTaxOffice && (
                    <Form.Item
                        style={{ width: '350px' }}
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: 'Lütfen ünvan giriniz.',
                            },
                        ]}>
                        <Input onChange={userForm}  placeholder="Ünvan" />
                    </Form.Item>
                )}

            </Row>
        </>
    );
}

export default UserStepForm;
