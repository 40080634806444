import React, { useState, useEffect  } from 'react';
import moment from 'moment';
import 'moment-timezone';
import { Col, Row ,DatePicker, Button, Input, Form, Spin, notification} from 'antd';
import { CalendarOutlined, SearchOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import AliciTedarikci from '../../../assests/alici_tedarikci.png';
import Finans from '../../../assests/finans.png';
import Tedarikciler from '../../../assests/tedarikciler.png';
import FaturaVadesi from '../../../assests/faturavadesi.png';
import Table from '../../../components/Table';
import Text from '../../../components/Text';
import { convertFloatDotSeperated } from '../../../utils';
import { fetchInvoices, calculateDiscountAmountApi} from '../../../apiServices/supplierFinanceApi';
import { setInvoices, setFinancialList,setRisk, setTotalInvoices, setAverageMaturitys,setSelectInvoices,setSelectBuyers } from '../../../store/reducers/supplierFinanceSlice';
import urls from '../../../routes/urls';
import { HelpBox, BottomCol } from './styles';
import { fetchUser,fetchUserRisks } from '../../../apiServices/userApi';
import { setUser } from '../../../store/reducers/userSlice';
import LocalStorageService from '../../../services/LocalStorageService';

const SupplierFinancingList = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [selectInvoice, setSelectInvoice] = useState({});
    const [active, setActive] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);
    const [boxcActive, setBoxActive] = useState(false);

    const { invoices } = useSelector((state) => state.supplierFinance);
    const { user, isLoggedIn } = useSelector((state) => state.user);
    const [riskLimitData,setRiskLimitData] = useState([]);
    const [filteredInfo, setFilteredInfo] = useState(null);
    const [invoicesData,setInvoicesData] = useState([]);
    const [filters, setFilters] = useState({
        invoiceDateBegin: '',
        invoiceDateEnd: '',
        invoiceTermBegin: '',
        invoiceTermEnd: '',
        minInvoiceAmount:'',
        maxInvoiceAmount:'',
        invoiceNumber:'',
      });
    const [totalInvoice,setTotalInvoice] = useState();
    const [averageMaturity, setAverageMaturity] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const [pSize, setPsize] = useState(10);
    const [pNumber, setpNumber] = useState(1);
    const [totalDataCount, setTotalDataCount] = useState(0);
    const [pageSelections, setPageSelections] = useState({}); // To store selections for each page

    const [form] = Form.useForm();

    const getRisks = async () => {
      const response = await fetchUserRisks(LocalStorageService.getAuthToken());
      if (response) {
        dispatch(setRisk(response.supplierRiskAndLimits))
        setRiskLimitData(response.supplierRiskAndLimits)
      }
    };

    const getTotal = (data) => {
      let totalAmount = 0;
      data.length && data.map((val) => (totalAmount += val.invoiceTotal));
      return totalAmount;
    };

    const discountInvoices = [];

    const getTotalCal = async (data) => {
      data && data.map((item) => {
        discountInvoices.push({
          invoiceTerm: item.invoiceTerm,
          invoiceAmount: item.invoiceTotal
        })
        return true
      })
      setPageLoading(true);

      const ChequeAmount = await getTotal(data);
      setTotalInvoice(ChequeAmount);
      dispatch(setTotalInvoices(ChequeAmount));
      const response = await calculateDiscountAmountApi({ discountInvoices });

      if(response.calculatedDiscountAmounts.length > 0 ){
        setAverageMaturity(response.calculatedDiscountAmounts);
        dispatch(setAverageMaturitys(response.calculatedDiscountAmounts));
        setBoxActive(false);
        setActive(false);

        if (response) {
          setBoxActive(true);
          setActive(true);
          setPageLoading(false);
          const nData = [];
          riskLimitData.map(async (item) => {
            const calculatedDiscountAmounts = response.calculatedDiscountAmounts.filter((itemInv) => itemInv.financialInstitutionId === item.financialInstitutionId);
            nData.push({
              financialName: item.financialInstitutionName,
              financialId: item.financialInstitutionId,
              total: ChequeAmount,
              price: calculatedDiscountAmounts[0]?.calculatedSumAmount,
              supplierAverageCreditDay: calculatedDiscountAmounts[0]?.averageMaturity,
              supplierLimit: item.supplierLimit,
              supplierRisk: item.supplierRisk,
              financialTaxId: item.financialInstitutionTaxId,
            })
            return true
          });
          dispatch(setFinancialList(nData.sort((a, b) => b.price - a.price)))
        }

      }else{
        setPageLoading(false);
        notification.warning({
          message: 'Uyarı',
          description: (
            <div className='common-err'>
                Lütfen bizimle <a href="mailto:destek@depar.io" style={{ color: '#1890ff', cursor: 'pointer' }}>
                destek@depar.io
                </a>. ya da 
                <br />
              <a href="tel:+5348123264" style={{ color: '#1890ff', cursor: 'pointer' }}>0534 812 32 64 </a> numaralı telefondan iletişime geçin.
                </div>
          ),
          duration: 8
        });
        setTimeout(() => {
          setSelectedRowKeys([])
        }, 2000);
      }
    };

const handleShowSize = (current, size) => {
  setpNumber(current);
  setPsize(size);
  const currentPageSelections = pageSelections[current] || { rowKeys: [], rows: [] };
  setSelectedRowKeys(currentPageSelections.rowKeys);
};

const onSelectChange = (newSelectedRowKeys, newSelectedRows) => {
  setSelectedRowKeys((prevSelectedRowKeys) => {
    const combinedKeys = [...prevSelectedRowKeys, ...newSelectedRowKeys];
    return [...new Set(combinedKeys)];
  });

  setPageSelections((prevSelections) => ({
    ...prevSelections,
    [pNumber]: {
      rowKeys: newSelectedRowKeys,
      rows: newSelectedRows,
    },
  }));
};

const rowSelection = {
  selectedRowKeys,
  onChange: onSelectChange,
  onSelect: (record, selected) => {
    if (!selected) {
      setSelectedRowKeys((prevKeys) => prevKeys.filter((item) => item !== record.id));
    }
  },
  onSelectAll: (selected) => {
    if (selected) {
        // tüm seçim
    } else {
      setSelectedRowKeys([]);
    }
  },
};

  const getSelectedData = (selectedRowsData) => {
     selectedRowsData > 0 ? setActive(true) : setActive(false); setBoxActive(false);
        if (selectedRowsData.length > 0) {
          const filterRows = selectedRowsData.reduce((newData, row) => {
            dispatch(setSelectBuyers({ buyerTitle: row.buyerTitle, buyerTaxNumber: row.buyerTaxNumber }));
            newData.push({
              id: row.id,
              invoiceDate: row.invoiceDate,
              fileName: row.fileName,
              invoiceNumber: row.invoiceNumber,
              invoiceTotal: row.invoiceTotal,
              currency: row.currency,
              invoiceTerm: row.invoiceTerm,
              isApproved: row.isApproved,
              createdDate: row.createdDate,
              hashCode: row.hashCode,
              invoiceType: row.invoiceType
            });
            return newData;
          }, []);
         
          setSelectInvoice(filterRows);
          dispatch(setSelectInvoices(filterRows));
          getTotalCal(selectedRowsData);
        } else {
          setSelectInvoice({});
        }
  }

  const getInvoice = async () => {
        try {
            setLoading(true);
            const {
                invoiceDateBegin,
                invoiceDateEnd,
                invoiceTermBegin,
                invoiceTermEnd,
                minInvoiceAmount,
                maxInvoiceAmount,
                invoiceNumber,
              } = filters;     
              const filterStr = `${invoiceDateBegin ? `invoiceDateBegin=${invoiceDateBegin}&` : ''}
                ${invoiceDateEnd ? `invoiceDateEnd=${invoiceDateEnd}&` : ''}
                ${invoiceTermBegin ? `invoiceTermBegin=${invoiceTermBegin}&` : ''}
                ${invoiceTermEnd ? `invoiceTermEnd=${invoiceTermEnd}&`: ''}
                ${minInvoiceAmount ? `minInvoiceAmount=${minInvoiceAmount}&` : ''}
                ${maxInvoiceAmount ? `maxInvoiceAmount=${maxInvoiceAmount}&` : ''}
                ${invoiceNumber ? `invoiceNumber=${invoiceNumber}&` : ''}
                `;
            const response = await fetchInvoices(user.taxId, isLoggedIn, filterStr.trim().replaceAll(/\s/g,''), pSize, pNumber); 
            if (response) {
                setLoading(false);
                setInvoicesData(response.invoices.data === null ? [] : response.invoices.data.sort((a, b) => b.id - a.id))
                dispatch(setInvoices(response.invoices.data.sort((a, b) => b.id - a.id)));
                setTotalDataCount(response.invoices.totalDataCount);
            } else {
                setLoading(false);
            }
        } catch (e) {
            setLoading(false);
        }
    };

  const seeOffer = () => {
      history.push(urls.financeOffers);
    }


  const getUserData = async () => {
        const response = await fetchUser(isLoggedIn);
        if (response) {
            dispatch(setUser(response));
        }
    };

  const handleDateSearch = (confirm) => {
        getInvoice()
        confirm();
      };
    
  const handleReset = (clearFilters) => {
         clearFilters();
        form.resetFields();
        setFilteredInfo(null)
        setFilters({
            ...filters, 
            invoiceDateBegin: '',
            invoiceDateEnd: '',
            invoiceTermBegin: '',
            invoiceTermEnd: '',
            });
      };

  const handleInvoiceNumberReset = () => {
        form.resetFields();
        setFilters({
            ...filters, 
            invoiceNumber: ''
            });
      };

  const handleTotalReset = () => {
        form.resetFields();
        setFilters({
            ...filters, 
            minInvoiceAmount:'',
            maxInvoiceAmount:'',
            });
      };
    

  const checkDate = (checkdate, date1, date2) => (
         moment(moment(checkdate).format('YYYY-MM-DD')).isBetween(moment(moment(date1).format('YYYY-MM-DD')), moment(moment(date2).format('YYYY-MM-DD')))
      )

    const getColumnInvoiceNumberProps = () => ({
        filterDropdown: ({ setSelectedKeys,selectedKeys,confirm}) => (
            <div style={{ padding: 10, display: 'grid', justifyItems: 'center' }}>
              <Text style={{ fontWeight: 'bold', marginBottom: 10 }}> Fatura No Giriniz</Text>
              <div>
                <Form form={form}>
                    <Form.Item
                        value={selectedKeys[0]}
                        style={{marginBottom:'0'}}
                        name='invoiceNumber'
                        onBlur={(e) => {
                        setSelectedKeys(e.length ? [e] : [])
                        setFilters({
                            ...filters, 
                            invoiceDateBegin:filters.invoiceDateBegin,
                            invoiceDateEnd:filters.invoiceDateEnd,
                            invoiceTermBegin:filters.invoiceTermBegin,
                            invoiceTermEnd: filters.invoiceTermEnd,
                            minInvoiceAmount:filters.minInvoiceAmount,
                            maxInvoiceAmount:filters.maxInvoiceAmount,
                            invoiceNumber: e.target.value || filters.invoiceNumber
                        })
                    }}
                    >
                    <Input allowClear />
                    </Form.Item>
                </Form>
               </div>
              <div style={{ marginTop: 15 }}>
                <Button
                  style={{ width: 90 }}
                  onClick={() => handleInvoiceNumberReset()}
                  size="small"
                >
                  Sıfırla
                </Button>
                <Button
                  type="primary"
                  role="search"
                  onClick={() => {
                    handleDateSearch( confirm)
                  }}
                  style={{ width: 90, height: 24, borderRadius: 0, marginLeft: 8 }}
                  icon={<SearchOutlined />}
                  size="small"
                >
                  Ara
                </Button>
              </div>
            </div>
          ),
      });
    
    const getColumnTotalProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys,selectedKeys,confirm}) => (
            <div style={{ padding: 10, display: 'grid', justifyItems: 'center' }}>
              <Text style={{ fontWeight: 'bold', marginBottom: 10 }}>Tutar Aralığı Giriniz</Text>
              <div >
                <Form form={form} style={{display:'flex', gap:'10px', width:'300px'}}>
                    <Form.Item
                        value={selectedKeys[0]}
                        name='minInvoiceAmount'
                        style={{marginBottom:'0'}}
                        onChange={(e) => {
                            setSelectedKeys(e.length ? [e] : [])
                            setFilters({
                               ...filters, 
                               invoiceDateBegin:filters.invoiceDateBegin,
                               invoiceDateEnd:filters.invoiceDateEnd,
                               invoiceTermBegin:filters.invoiceTermBegin,
                               invoiceTermEnd: filters.invoiceTermEnd,
                               minInvoiceAmount:e.target.value || filters.minInvoiceAmount,
                               maxInvoiceAmount:filters.maxInvoiceAmount,
                               invoiceNumber: filters.invoiceNumber
                             })
                           }}
                    >
                    <Input allowClear  placeholder='min tutar' />
                    </Form.Item>
                    <Form.Item
                       value={selectedKeys[0]}
                       style={{marginBottom:'0'}}
                       name='maxInvoiceAmount'
                       onChange={(e) => {
                           setSelectedKeys(e.length ? [e] : [])
                           setFilters({
                             ...filters, 
                             invoiceDateBegin:filters.invoiceDateBegin,
                             invoiceDateEnd:filters.invoiceDateEnd,
                             invoiceTermBegin:filters.invoiceTermBegin,
                             invoiceTermEnd: filters.invoiceTermEnd,
                             minInvoiceAmount:filters.minInvoiceAmount,
                             maxInvoiceAmount:e.target.value || filters.maxInvoiceAmount,
                             invoiceNumber: filters.invoiceNumber
                           })
                         }}
                    >
                    <Input allowClear  placeholder='max tutar' />
                    </Form.Item>
                </Form>
            
              </div>
              <div style={{ marginTop: 15 }}>
                <Button
                  style={{ width: 90 }}
                  onClick={() => handleTotalReset()}
                  size="small"
                >
                  Sıfırla
                </Button>
                <Button
                  type="primary"
                  role="search"
                  onClick={() => {
                    handleDateSearch( confirm)
                  }}
                  style={{ width: 90, height: 24, borderRadius: 0, marginLeft: 8 }}
                  icon={<SearchOutlined />}
                  size="small"
                >
                  Ara
                </Button>
              </div>
            </div>
          ),
          filteredValue: (filteredInfo && filteredInfo[dataIndex]) || null,
      });

    const getColumnTimeProps = (dataIndex) => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm,clearFilters}) => (
          <div style={{ padding: 10, display: 'grid', justifyItems: 'center' }}>
            <Text style={{ fontWeight: 'bold', marginBottom: 10 }}>Tarih Aralığı Giriniz</Text>
            <DatePicker.RangePicker
              onChange={(e) => {
                setSelectedKeys(e.length ? [e] : [])
                setFilters({
                  ...filters,
                  invoiceDateBegin: (dataIndex === 'invoiceDate' && e.length > 0 && new Date(e[0]).toISOString()) || filters.invoiceDateBegin,
                  invoiceDateEnd: (dataIndex === 'invoiceDate' && e.length > 0 && new Date(e[1]).toISOString()) || filters.invoiceDateEnd,
                  invoiceTermBegin: (dataIndex === 'invoiceTerm' && e.length > 0 && new Date(e[0]).toISOString()) || filters.invoiceTermBegin,
                  invoiceTermEnd: (dataIndex === 'invoiceTerm' && e.length > 0 && new Date(e[1]).toISOString()) || filters.invoiceTermEnd,
                  minInvoiceAmount:filters.minInvoiceAmount,
                  maxInvoiceAmount:filters.maxInvoiceAmount,
                  invoiceNumber: filters.invoiceNumber
                })
              }}
              allowClear={false}
              style={{ width: 190 }}
              value={selectedKeys[0]}
              format="YYYY-MM-DD"
            />
            <div style={{ marginTop: 15 }}>
              <Button
                style={{ width: 90 }}
                onClick={() => handleReset(clearFilters)}
                size="small"
              >
                Sıfırla
              </Button>
              <Button
                type="primary"
                role="search"
                onClick={() => {
                  handleDateSearch(confirm)
                }}
                style={{ width: 90, height: 24, borderRadius: 0, marginLeft: 8 }}
                icon={<SearchOutlined />}
                size="small"
              >
                Ara
              </Button>
            </div>
          </div>
        ),
        filterIcon: (filtered) => (
          <CalendarOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        // filteredValue: (filteredInfo && filteredInfo[dataIndex]) || null,
        onFilter: (value, record) => record[dataIndex] ? checkDate(record[dataIndex],value[0], value[1]) : '',
        render: (text) => moment(text).format('DD-MM-YYYY')
      });
   
    const columns = [
        {
            title: 'Fatura No',
            dataIndex: 'invoiceNumber',
            key: 'invoiceNumber',
            fixed: 'left',
            ...getColumnInvoiceNumberProps('invoiceNumber'),
        },
        {
            title: 'Fatura Tarihi',
            dataIndex: 'invoiceDate',
            key: 'invoiceDate',
            render: (value) => moment(value).format('DD-MM-YYYY'),
            ...getColumnTimeProps('invoiceDate'),
        },
        {
            title: 'Borçlu VKN',
            dataIndex: 'buyerTaxNumber',
            key: 'buyerTaxNumber',
        },
        {
            title: 'Borçlu Ünvanı',
            dataIndex: 'buyerTitle',
            key: 'buyerTitle',
        },
        {
            title: 'Fatura Tutarı',
            dataIndex: 'invoiceTotal',
            key: 'invoiceTotal',
            render: convertFloatDotSeperated,
            ...getColumnTotalProps('invoiceTotal'),
        },
        {
            title: 'Fatura Vadesi',
            dataIndex: 'invoiceTerm',
            key: 'invoiceTerm',
            render: (value) => moment(value).format('DD-MM-YYYY'),
            ...getColumnTimeProps('invoiceTerm'),
        },
    ];

   
    useEffect(() => {
        isLoggedIn && getUserData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn]);

  useEffect(() => {
    const allSelectedRows = Object.values(pageSelections).reduce((acc, pageData) => [
      ...acc, 
      ...pageData.rows
    ], []);
    const uniqueArray = allSelectedRows.reduce((acc, current) => 
      acc.some(item => item.id === current.id) ? acc : [...acc, current]
    , []);
    getSelectedData(uniqueArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSelections]); 

    useEffect(() => {     
        if (user && user.taxId) {
            getInvoice();
            getRisks();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user,pNumber, pSize,
        filters.maxInvoiceAmount ==='' && filters.minInvoiceAmount === '', 
        filters.invoiceTermBegin ==='' && filters.invoiceTermEnd === '',
        filters.invoiceDateBegin ==='' && filters.invoiceDateEnd === '',
        filters.invoiceNumber === ''
    ]);

    return (
        <>
        <Spin tip="Hesaplanıyor.." spinning={pageLoading}>
            {!loading && invoices && invoices.length === 0 ? (
                <Row>
                    <Row style={{ width: '68%', margin: '40px auto 0px' }}>
                        <Col style={{ margin: '0px auto', textAlign: 'center', display: 'grid' }}>
                            <Text style={{ fontSize: 18, fontWeight: 800, marginBottom: 20 }}>
                                Yüklenen faturalarınız bulunmamaktadır.
                            </Text>
                            <Text>
                                Bu fatura türünü görmeniz için alıcının fatura yüklemesi gerekmektedir.
                            </Text>
                            <Text>
                                Alıcı bu faturaları yükledikten sonra sistemimizde yüklenen faturalarınızı
                                görebilirsiniz.
                            </Text>
                        </Col>
                    </Row>
                    <HelpBox>
                        <Col className="info_box">
                            <img src={AliciTedarikci} alt="alici_tedarikci" />
                            <Text>
                                Alıcı, Tedarikçi faturalarını sisteme yüklediğinde, Tedarikçi'ye otomatik
                                bildirim iletilir.
                            </Text>
                        </Col>
                        <Col className="info_box">
                            <img src={Tedarikciler} alt="Tedarikciler" />
                            <Text>
                                Tedarikçi, bu faturalardan uygun bulduklarını seçerek erken ödeme almak için
                                teklif ister.
                            </Text>
                        </Col>
                     
                        <Col className="info_box">
                            <img src={Finans} alt="finans" />
                            <Text>Finans kurumları, işletmelerin faturalarına teklif verir.</Text>
                        </Col>
                        <Col className="info_box">
                            <img src={FaturaVadesi} alt="FaturaVadesi" />
                            <Text>
                                Fatura vadesi geldiğinde, Alıcı fatura ödemesini ilgili finans kurumuna
                                gerçekleştirir.
                            </Text>
                        </Col>
                    </HelpBox>
                </Row>
            ) : (
                <>
                     <Row style={{ marginTop:'20px', marginBottom:'10px'}}>
                        <Col xl={24}>
                        <Text className='page-title'>Yüklenen Faturalarım</Text>
                        </Col>
                    </Row>
                    <Row style={{marginBottom:'30px'}}>
                        <Col span={24} style={{ margin: '30px auto 30px' }}>
                            <Table
                                className="invoiceTable"
                                pagination={{
                                    pageSizeOptions: ['10', '20', '50', '100', '250', '500'],
                                    position: ['topRight', 'none'],
                                    showLessItems: true,                          
                                    showSizeChanger: true,
                                    current: pNumber,
                                    pageSize: pSize,
                                    total:totalDataCount,                                                       
                                    onChange: handleShowSize, 
                                }}
                                rowSelection={{
                                    type: 'checkbox',
                                    ...rowSelection,
                                }}
                                rowKey="id"
                                dataSource={invoicesData}
                                columns={columns}
                                loading={loading}
                                cursorPointer
                                scroll={{
                                    x: 1200,
                                  }}
                            />
                        </Col>
                    </Row>
                   <Row className='financial-list-row'>
                    {
                      !active && !boxcActive ?
                      <Col style={{textAlign:'center', width:'100%'}}>
                      <h3>İskonto ettirmek istediğiniz faturaları seçiniz.</h3>
                     </Col>
                     :
                     <Col style={{textAlign:'center',margin:'0 auto'}}>
                      <BottomCol>
                        <h3 className='bottom-col-h3'>Seçmiş Olduğunuz Faturaların Özeti:</h3>
                        <div className='drop-selected-bar'>
                          <div className='drop-selected-div'>
                            <div className='drop-selected-div-svg'>
                              <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 384 512"
                                  width="24" 
                                  height="24" 
                                  aria-hidden="true" 
                              >
                                  <path
                                  d="M64 0C28.7 0 0 28.7 0 64L0 448c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-288-128 0c-17.7 0-32-14.3-32-32L224 0 64 0zM256 0l0 128 128 0L256 0zM112 256l160 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-160 0c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64l160 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-160 0c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64l160 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-160 0c-8.8 0-16-7.2-16-16s7.2-16 16-16z"
                                  fill="currentColor" 
                                  />
                              </svg>
                            </div>
                            <div className='drop-selected-div-txt'>
                              <h4>Fatura Adedi</h4>
                              <span>{(selectInvoice && selectInvoice.length) || 0}</span>
                            </div>
                          </div>
                          <div className='drop-selected-div'>
                            <div className='drop-selected-div-svg'>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                                width="24" 
                                height="24" 
                                aria-hidden="true" 
                            >
                                <path
                                d="M128 0c17.7 0 32 14.3 32 32l0 32 128 0 0-32c0-17.7 14.3-32 32-32s32 14.3 32 32l0 32 48 0c26.5 0 48 21.5 48 48l0 48L0 160l0-48C0 85.5 21.5 64 48 64l48 0 0-32c0-17.7 14.3-32 32-32zM0 192l448 0 0 272c0 26.5-21.5 48-48 48L48 512c-26.5 0-48-21.5-48-48L0 192zm64 80l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm128 0l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zM64 400l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zm112 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16z"
                                fill="currentColor" 
                                />
                            </svg>
                            </div>
                            <div className='drop-selected-div-txt'>
                              <h4>Ortalama Vade</h4>
                              <span>{averageMaturity[0].averageMaturity} Gün</span>
                            </div> 
                          </div>
                          <div className='drop-selected-div'>
                            <div className='drop-selected-div-svg'>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 384 512"
                                width="24" 
                                height="24"
                                aria-hidden="true" 
                            >
                                <path
                                d="M96 32c17.7 0 32 14.3 32 32l0 35.3L247.2 65.2c17-4.9 34.7 5 39.6 22s-5 34.7-22 39.6L128 165.9l0 29.4 119.2-34.1c17-4.9 34.7 5 39.6 22s-5 34.7-22 39.6L128 261.9 128 416l63.8 0c68.2 0 124.4-53.5 127.8-121.6l.4-8c.9-17.7 15.9-31.2 33.6-30.4s31.2 15.9 30.4 33.6l-.4 8C378.5 399.8 294.1 480 191.8 480L96 480c-17.7 0-32-14.3-32-32l0-167.9-23.2 6.6c-17 4.9-34.7-5-39.6-22s5-34.7 22-39.6L64 213.6l0-29.4-23.2 6.6c-17 4.9-34.7-5-39.6-22s5-34.7 22-39.6L64 117.6 64 64c0-17.7 14.3-32 32-32z"
                                fill="currentColor" 
                                />
                            </svg>
                            </div>
                            <div className='drop-selected-div-txt'>
                              <h4>Fatura Toplamı</h4>
                              <span>{convertFloatDotSeperated(totalInvoice)} TL</span>
                            </div>
                          </div>
                          <div className='drop-selected-btn'>
                              <Button className='see-offer-btn' onClick={seeOffer}>Teklifleri Gör</Button>
                          </div>
                        </div>
                      </BottomCol>
                    </Col>
                    }
                   </Row>
                </>
            )}
        </Spin>
      
        </>
    );
};

export default SupplierFinancingList;
