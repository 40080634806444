import styled from 'styled-components';

export const BuyersListBox = styled.div`
   padding:40px 0;
    .noti-row {
        width:100%;
        margin-bottom:60px;
        text-align:center;
        font-size: 18px;
        font-weight: 800;
        margin-bottom: 20px;
    }
    .underline{ margin-top: 6px;}
    .buyers-list-table{
        width:100%
    }
    .table-row{
        display:block
    }
    .ant-btn-primary[disabled] span {
        color: #000;
        border-color: #d9d9d9;
        background: #f5f5f5;
        text-shadow: none;
        box-shadow: none;
    }
`;

