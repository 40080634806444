import React from 'react';
import { Col, Row } from 'antd';


function Help() {
  
    return (
        <Row>
            <Col span={24}>
                <p style={{fontSize:'16px'}}>
                    Lütfen bizimle <a href="mailto:destek@depar.io" style={{ color: '#1890ff', cursor: 'pointer' }}>
                    destek@depar.io
                    </a>. ya da 
                    <br />
                <a href="tel:+5348123264" style={{ color: '#1890ff', cursor: 'pointer' }}>0534 812 32 64 </a> numaralı telefondan iletişime geçiniz.
                </p>    
            </Col>
        </Row>
    );
}
export default Help;
