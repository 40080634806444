import React, {useEffect, useState} from 'react';
import { Row, Col, Button, Table, notification, Modal } from 'antd';
import {getUsersDataApi, deleteUserApi} from '../../apiServices/supplierFinanceApi'
// import { useNavigate } from "react-router-dom";


function UserTracing() {
  // let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [users, setUsers] = useState();
  const [deleteUser, setDeleteUser] = useState();
  const [psize, setPsize] = useState(10);
  const [page, setpage] = useState(1);
  const [totalDataCount, setTotalDataCount] = useState(1);
 
  const getUsersData = async () => {
   setLoading(true)
    const response = await getUsersDataApi();
    if(response){
      setUsers(response)
      setLoading(false)
      setTotalDataCount(10)
    }
  }

  const handleShowSize = (current, size) => {
    setpage(current);
    setPsize(size);
  };

  const deleteUsers = async (id) => {
    if(deleteUser){
      try {
        await deleteUserApi(id);
          notification.success({
            message: `Kullanıcı başarıyla silinmiştir.`,
        }); 
          getUsersData();
          setDeleteUser('');
        
      } catch (error) {
        console.log(error);
      }
    }
  }

  const showModal = (id) => {
    setOpen(true);
    setDeleteUser(id);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
    if(deleteUser){
      deleteUsers(deleteUser);
    }else{
      setOpen(false);
    }
     
  };
  const handleCancel = () => {
    setOpen(false);
  };


  useEffect(() => {
    getUsersData()
  },[])

  const columns = [
    {
      title: 'Adı Soyadı',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      width: 200,
      sorter: {
        compare: (a, b) => a.name - b.name,
        multiple: 2,
      },
    },
    {
      title: 'E-posta Adresi',
      dataIndex: 'email',
      key: 'email',
      width: 150,
    },
    {
      title: 'Cep Telefonu',
      dataIndex: 'phone',
      key: 'phone',
      width: 150,
    },
    {
      title:'',
      dataIndex:'deleteUser',
      key:'deleteUser', 
      render: (_, record) => (
        <Button 
        type='primary' 
        onClick={ () =>  showModal(record.id) }
        > Sil
        </Button> 
      ),        
    } 
  ];

  return (
    <main>
      <Row>
        <Col span={24}>
          <h3 className="page-title">Kullanıcı Listesi</h3>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
        <Table
          //  className="w-100 invoiceTable"
           style={{width:'auto'}}
           pagination={{
             position: ['topRight', 'none' ],
             showLessItems: true,                          
             showSizeChanger: true,
             current: page,
             pageSize:psize,
             total:totalDataCount, 
             onChange: handleShowSize,
             
           }}
          loading={loading}
          rowKey='id'
          columns={columns}
          dataSource={users}
          scroll={{
            x: 1200,
          }}
          
        />
        </Col>
      </Row>
      <Modal
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        okText="Evet"
        cancelText="Hayır"
        className="user-delete-modal"
      >
        <p>İşlemi yapmak istediğinizden emin misiniz?</p>
      </Modal>

    </main>
  );
};

export default UserTracing;
