import styled from 'styled-components';

export const LeftSideBox = styled.div`
    display: grid;
    width: 320px;
    margin-right: 20px;
    .head-title {
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #001444;
    }
    .status-box {
        width: 320px;
        height: 64px;
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 32px 0px 0px;
        gap: 49px;
        padding: 16px;
        object-fit: contain;
        background-color: #f4f4f4;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
    .status-title {
        flex-grow: 0;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        margin-left: 8px;
        letter-spacing: normal;
        text-align: left;
        color: #131112;
    }
    .item-title {
        width: 320px;
        flex-grow: 0;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: left;
        color: #001444;
    }
    .item-value {
        flex-grow: 0;
        font-size: 13px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: right;
        color: #131112;
    }
    .item-row {
        margin-bottom: 15px;
    }
    .item-box {
        width: 320px;
        height: max-content;
        flex-grow: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 16px;
        padding: 16px;
        border: solid 1px #eef4ff;
        background-color: #fff;
    }
    .left-btn{
        background-color:#0d0f7b;
        margin-top: 20px;
        border-radius: 8px;
        height: 44px;
        width: 90% !important;
    }
    @media only screen and (max-width: 990px){
        width: 100%;
        .item-box{
            width: 100%;
        }
        .status-box {
            width: 100%;
        }
        .item-title {
            width: 100%;
        }
        .left-btn{
            width: 40% !important;
        }
    }
    @media only screen and (max-width: 500px){
       
        .left-btn{
            width: 100% !important;
        }
    }
`;

export const SuccessBox = styled.div`
    display: grid;
    justify-content: center;
    gap: 20px;
    text-align: center;
    .tick-icon {
        font-size: 48px;
        color: #5ad363;
    }
    .download-icon {
        font-weight: bold;
        font-size: 20px;
        margin-right: -10px;
    }
    .files-divider {
        height: 24px;
        border-left: 1.5px solid #e1e1e1;
        width: 49%;
        margin-left: auto;
    }
    .num-round {
        width: 32px;
        height: 32px;
        flex-grow: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 16px;
        border-radius: 33px;
        border: solid 1px #e1e1e1;
        background-color: #fff;
        margin: 0px auto;
    }
    .download-temlik {
        width: 230px;
        height: 45px;
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 12px;
        padding: 15px;
        border-radius: 8px;
        background-color: #f4f4f4;
        border: 0px;
        color: #131112;
        font-weight: bold;
        margin: 0px auto;
    }
    .add-temlik {
        width: 250px;
        height: 45px;
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 12px;
        padding: 15px;
        border-radius: 8px;
        background-color: #e6f1ff;
        border: 0px;
        color: #131112;
        font-weight: bold;
        margin: 0px auto;
    }
`;

export const BottomSideBox = styled.div`
    width: 100%;
    height: 88px;
    border-radius: 8px;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.25);
    border: solid 1px #eef4ff;
    margin: 30px 0;
    padding: 20px;
    background-color: #fafbff;
    .box-div{
        display:flex;
        align-items: center;
        justify-content: space-between;
    }
    .header-table {
        display: flex;
        width: 100%;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
    }
    .header-title {
        -webkit-box-flex: 0;
        flex-grow: 0;
        // margin: 12.5px 84.4px 12.5px 0px;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: rgb(0, 20, 68);
        min-width: 234px;
        white-space: nowrap;
    }
    .text-title {
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: center;
        color: rgb(0, 20, 68);
    }
    @media only screen and (max-width: 767px){
        height: auto;
        .box-div{
            flex-direction: column;
            align-items: self-start;
        }
        .header-table{
            align-items: self-start;
        }
       
    }
`;

export const ScreenContent = styled.div`
    padding-top:50px;
    .ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before {
    position: absolute;
    right: 0;
    left: 0;
     border-bottom:none;
    content: '';
    }
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
        justify-content: center;
    }
    .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
        margin: 0;
        padding: 8px 16px;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        width: 235px;
        height: 42px;
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 8px;
        margin: 0 28px 0 0;
        padding: 6px 40px 5px;
        border-radius: 56px;
        border: solid 1px #ececec;
        background-color: #fff;
        color: #001444;
        font-size: 14px;
        font-weight: 600;
    }
    .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
        color: #1890ff;
        background-color: #fafbff;
       // border: solid 1px #001444;
        color: #001444;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{  color: #1890ff;}
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #001444;
        text-shadow: 0 0 0.25px currentcolor;
    }
    .ant-table-thead > tr > th {
        position: relative;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
        text-align: left;
        background-color: #fafbff;
        border-bottom:none;
        transition: background 0.3s ease;
       
    }
    .ant-table{
        border: solid 1px #eef4ff;
    }
    .ant-table-tbody > tr > td {
        border-bottom: 1px solid #eef4ff;
        transition: background 0.3s;
    }
    .ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-row > tr > td.ant-table-cell-row-hover{
        background-color: #fafbff;
    }
    @media only screen and (max-width: 460px){
        .ant-tabs-nav-list{
            flex-direction: column;
            gap:15px
        }
    }
   
`;