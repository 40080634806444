import React, { useState } from 'react';
import { Col, Row, Form, Input, notification } from 'antd';
import Text from '../../../components/Text';
import Button from '../../../components/Button';
import {  resetPasswordstring} from '../../../apiServices/userApi';
import { validatePassword } from '../../../utils/validators';

function PasswordUpdate() {

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
 
    const sendCode = async ( values ) => {
        try{
            if(values.newPassword === values.confirmNewPassword){
                setLoading(true)
                 await resetPasswordstring(values);
                form.resetFields(); 
                setLoading(false)
            }else{
                notification.warning({
                    message: `Yeni şifre ile şifre onayınız aynı olmalıdır.`,
                });
                setLoading(false)
            }
        }catch(e){
            console.log(e)
            setLoading(false)
        } 
    };
  

    return (
        <Row>
            <Col xs={24} md={14} lg={7} style={{ margin: '30px auto' }}>
                <Text type="title" style={{  marginBottom:'20px'}} className="page-title">
                    Şifre Değiştir
                </Text>   
                <Text style={{  marginBottom:'20px', display:'block'}}>Kullanıcı şifrenizi güncelleyin.</Text>
             
                <Form 
                    name="sendCode" 
                    onFinish={sendCode}
                    className="d-block email-input" 
                    labelCol={{ span: 5 }}
                    layout="horizontal"
                    form={form}
                    >
                        <Form.Item
                            name="oldPassword"
                                rules={[
                                {
                                 required: true,
                                 message: 'Değiştirmek istediğiniz şifrenizi giriniz.' 
                                },
                                ]}
                                >
                            <Input.Password placeholder="Mevcut Şifreniz" />                      
                        </Form.Item>
                        <Form.Item
                            name="newPassword"
                            rules={[
                                    {
                                        required: true,
                                        validator: (_, value) => validatePassword(value),
                                    },
                                ]}
                                >
                            <Input.Password placeholder="Yeni Şifre" />                      
                        </Form.Item>
                        <Form.Item
                            name="confirmNewPassword"
                            rules={[
                                    {
                                        required: true,
                                        validator: (_, value) => validatePassword(value),
                                    },
                                ]}
                                >
                            <Input.Password placeholder="Yeni Şifre Tekrarla" />                      
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                size="large"
                                style={{width:'200px', margin:'0 auto', display:'block'}}
                                loading={loading}
                                block>
                                    Gönder
                            </Button>
                        </Form.Item>
                    </Form>

            </Col>
        </Row>
    );
}

export default PasswordUpdate;
