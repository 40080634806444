import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Popover, Divider } from 'antd';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import Icon, { CustomIcon } from '../Icon';
import AccountDropdownStyled, { AccountButton } from './styles';
import { logoutApi } from '../../apiServices/userApi';
import Text from '../Text';
import urls from '../../routes/urls';
import { setLoggedIn,setUser, setIsRun } from '../../store/reducers/userSlice';
import { setBuyerIdentity } from '../../store/reducers/supplierFinanceSlice';
import LocalStorageService from '../../services/LocalStorageService';

const AccountMenu = ({ setIsDropdownVisible }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const buyerIdentity =  LocalStorageService.getBuyerUser();
    const isAdmin = JSON.parse(LocalStorageService.getIsAdmin());

    const closeDropdown = () => {
        setIsDropdownVisible(false);
    };

    const onClickLogout = async () => {
        LocalStorageService.setIsLogout(true);
        await logoutApi();
        dispatch(setLoggedIn(''));
        dispatch(setUser(''));
        dispatch(setBuyerIdentity(''));
        dispatch(setIsRun(false));
        history.push(urls.login);
        LocalStorageService.removeUserEmail();
        LocalStorageService.removeBuyerUser();
        LocalStorageService.removeIsAdmin();
        LocalStorageService.removeAuthToken();
        LocalStorageService.removeIsLogout();
        window.location.reload();
    };

    return (
        <>
        {
           buyerIdentity && (

            <>
             <Link to={urls.generalInfo}>
                <AccountButton onClick={closeDropdown} >
                    <Text className="m-0" type="label">
                        Kullanıcı Bilgileri
                    </Text>
                    <Text type="small" color="smoke">
                        Kullanıcı bilgilerinizi görüntüleyin.
                    </Text>
                </AccountButton>
            </Link>

            <Divider className="m" />

            <Link to={urls.companyInfo}>
                <AccountButton onClick={closeDropdown} block>
                    <Text className="m-0" type="label" >
                        Firma Bilgileri
                    </Text>
                    <Text type="small" color="smoke">
                        Firmanıza ait bilgileri görüntüleyin.
                    </Text>
                </AccountButton>
            </Link>

            <Divider className="m" />

            <Link to={urls.changePassword}>
                <AccountButton onClick={closeDropdown} block>
                    <Text className="m-0" type="label">
                        Şifre İşlemleri
                    </Text>
                    <Text type="small" color="smoke">
                        Kullanıcı şifrenizi güncelleyin.
                    </Text>
                </AccountButton>
            </Link>

            <Divider className="m" />

            <Link to={urls.legalDocuments}>
                <AccountButton onClick={closeDropdown} block>
                    <Text className="m-0" type="label">
                        Firma Belgeleri
                    </Text>
                    <Text type="small" color="smoke">
                        Firma belgelerinizi ekleyin veya güncelleyin.
                    </Text>
                </AccountButton>
            </Link>

            <Divider className="m" />

            {
                 isAdmin ?  (
                    <>
                    <Link to={urls.newUser}>
                        <AccountButton onClick={closeDropdown} block>
                            <Text className="m-0" type="label">
                                Kullanıcı Tanımlama
                            </Text>
                            <Text type="small" color="smoke">
                                Platformu kullanabilmesi için başka kullanıcılar ekleyin.
                            </Text>
                        </AccountButton>
                    </Link>

                    <Divider className="m" />


                    <Link to={urls.userTracing}>
                        <AccountButton onClick={closeDropdown} block>
                            <Text className="m-0" type="label">
                                Kullanıcı İzleme
                            </Text>
                            <Text type="small" color="smoke">
                            Tanımlanmış kullanıcılarınızı görüntüleyin veya silin.
                            </Text>
                        </AccountButton>
                    </Link>

                    <Divider className="m" />
                    </>
                 ) : ''
            }

            <Link to={urls.help}>
                <AccountButton onClick={closeDropdown} block>
                    <Text className="m-0" type="label">
                        Yardım
                    </Text>
                    <Text type="small" color="smoke">
                        Sorularınızı cevaplayalım.
                    </Text>
                </AccountButton>
            </Link>
            <Divider className="m" />
            </>
           ) 
        }   
            <Link to={urls.login}>
                <AccountButton onClick={onClickLogout} block>
                    <Text className="m-0" type="label" color="red">
                        Çıkış
                    </Text>
                </AccountButton>
            </Link>
        </>
    );
};

function AccountDropdown() {
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);

    return (
        <AccountDropdownStyled>
            <Popover
                className="cursor-pointer dede" 
                content={() => <AccountMenu setIsDropdownVisible={setIsDropdownVisible} />}
                trigger="click"
                placement="bottomRight"
                onOpenChange={setIsDropdownVisible}
                open={isDropdownVisible}>
                <div>
                    <CustomIcon icon="user" size="lg" />
                    <Text color="primary" bold>
                        Hesabım
                    </Text>

                    <Icon className="mx" icon="angle-down" size="lg" />
                </div>
            </Popover>
        </AccountDropdownStyled>
    );
}


export default AccountDropdown;
