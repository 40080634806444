import React, {useState} from 'react';
import { Row, Col,Button, Form, Input, notification} from 'antd';
import {employeesNewUser} from '../../apiServices/supplierFinanceApi';
import { validateEmail, validateGsmNumber,validatePassword } from '../../utils/validators';

function NewUser(){
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish =  async (values) => {
    setLoading(true);
    const userData  = {
        name : values.name,
        surname : values.surname,
        emailAddress : values.email,
        phoneNumber : values.phoneNumber,
        password : values.password
    };
      try {
       await employeesNewUser(userData);
       setLoading(false);
          notification.success({
            message: `Başarıyla yeni kayıt oluşturuldu.`,
        }); 
        form.resetFields();
        
        } catch (e) {
      
          setLoading(false);
         
      }
  };


  return (
    <main>
      <Row style={{marginBottom:'30px'}}>
        <Col span={24}>
          <h3 className="page-title">Kullanıcı Tanımla</h3>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form
              form={form}
              name="user-update"
              className="default-form"
              onFinish={onFinish}
              labelCol={{
              span: 14,
              }}
              wrapperCol={{
                span: 14,
              }}
              layout="vertical"
              initialValues={{
                size: 'large',
              }}
            
              // size={'large'}
              style={{
                maxWidth: 600,
              }}
          >
            <Form.Item 
              label="Ad:"
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
              >
              <Input/>
            </Form.Item>
            <Form.Item 
              label="Soyad:"
              name="surname"
              rules={[
                {
                  required: true,
                },
              ]}
              >
              <Input/>
            </Form.Item>
            <Form.Item 
              label="E-posta Adresi:"
              name="email"
              rules={[
                {
                  required: true,
                  validator: (_, value) => validateEmail(value)
                },
              ]}
              >
              <Input/>
            </Form.Item>
            <Form.Item 
              label="Telefon Numarası:"
              name="phoneNumber"
              rules={[
                {
                  required: true,
                  validator: (_, value) => validateGsmNumber(value)
                },
              ]}
            >
              <Input/>
            </Form.Item>

            <Form.Item 
                label="Şifreniz:"
                name="password"
                rules={[
                  {
                    required: true,
                    validator: (_, value) => validatePassword(value)
                  },
                ]}
              >
                <Input.Password  style={{marginTop:'0px', width:'100%'}}  />
              </Form.Item>
          
            <Form.Item style={{marginTop:'35px'}}>
              <Button type="primary"  htmlType="submit" loading={loading}>Kullanıcı Oluştur</Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </main>
  );
};

export default NewUser;
