import React, { useState } from 'react';
import { Col, Row, Form, Input, notification } from 'antd';
import {  useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import Text from '../../../components/Text';
import Button from '../../../components/Button';
import urls from '../../../routes/urls';
import { resetPassword } from '../../../apiServices/userApi';
import { validatePassword } from '../../../utils/validators';

function PasswordReset() {
    const history = useHistory();
    const location = useLocation();
    const path = location.pathname.split('/');
    const updateId = path[2];
    const token = path[3];
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();

  
    const handleResetPassword = async (vals) => {
        setLoading(true)
        if(vals.newpassword !== vals.repeatpassword){
            notification.warning({
                message:
                    'Şifre ile tekrar edilen şifre aynı olmalıdır.',
            });
            setLoading(false);
        } else{
            await resetPassword({
                userId : updateId,
                resetPasswordToken: token,
                newPassword : vals.newpassword,
                newPasswordConfirmation : vals.repeatpassword
            });
            history.push(urls.supplierFinancing);
            form.resetFields();
            setLoading(false)
        }  
    };
  

    return (
        <Row>
            <Col xs={24} md={12} lg={6} style={{ margin: '30px auto' }}>
                <Text type="title" style={{  marginBottom:'20px'}}>
                    Şifre Yenile
                </Text>
                <Text style={{ marginBottom:'20px', display:'block'}}>Lütfen belirlemiş olduğunuz yeni şifrenizi onaylamak için iki defa giriniz.</Text>                  
                    <Form 
                        className="d-block update-input"
                        name="passwordReset" 
                        onFinish={handleResetPassword}
                        labelCol={{ span: 7 }}
                        layout="horizontal"
                        form={form}
                        >                            
                        <Form.Item
                            name="newpassword"
                            size='large'
                            rules={[
                                {
                                    required: true,
                                    validator: (_, value) => validatePassword(value)
                                },
                            ]}>
                            <Input.Password placeholder="Yeni Şifre" autoComplete="new-password" />
                        </Form.Item>

                        <Form.Item
                            name="repeatpassword"
                            rules={[
                                {
                                    required: true,
                                    validator: (_, value) => validatePassword(value)
                                },
                            ]}>
                            <Input.Password placeholder="Yeni Şifre Tekrar" autoComplete="new-password" />
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{width:'100%', margin:'0 auto', display:'block'}}
                                size="large"
                                loading={loading}
                                block>
                                Kaydet
                            </Button>
                        </Form.Item>
                    </Form>
            </Col>
        </Row>
    );
}

export default PasswordReset;
